import { mapObjectData } from 'util/helpers/mapper';

export const PRODUCT_FIELDS = {
    model: {
        name: 'model',
        label: 'Model *',
        required: true,
        options: 'models',
    },
    canopyColour: {
        name: 'canopyColour',
        label: 'Canopy Colour',
        required: false,
        options: 'colours',
    },
    canopyColourTwo: {
        name: 'canopyColourTwo',
        label: 'Second Canopy Colour',
        required: false,
        options: 'colours',
    },
    frameColour: {
        name: 'frameColour',
        label: 'Frame Colour',
        required: false,
        options: 'frames',
    },
    valance: {
        name: 'valance',
        label: 'Valance',
        required: false,
        options: 'colours',
    },
    base: { name: 'base', label: 'Base', required: false, options: 'bases' },
};

export const PRODUCT_TYPES = {
    revolvashade: {
        model: PRODUCT_FIELDS.model,
        canopyColour: {
            name: 'canopyColour',
            label: 'Canopy Colour *',
            required: true,
            options: 'colours',
        },
        canopyColourTwo: PRODUCT_FIELDS.canopyColourTwo,
        frameColour: PRODUCT_FIELDS.frameColour,
        valance: PRODUCT_FIELDS.valance,
        base: { name: 'base', label: 'Base *', required: true, options: 'bases' },
    },
    clevershade: PRODUCT_FIELDS,
    blacksheep: PRODUCT_FIELDS,
    // },
    // bags: {
    //     model: {
    //         name: 'model',
    //         label: 'Model *',
    //         required: true,
    //         options: 'models',
    //     },
    //     canopyColour: {
    //         name: 'canopyColour',
    //         label: 'Canopy Colours *',
    //         required: true,
    //         options: 'colours',
    //     },
    // },
    // bases: {
    //     model: {
    //         name: 'model',
    //         label: 'Model *',
    //         required: true,
    //         options: 'models',
    //     },
    //     frameColour: {
    //         name: 'frameColour',
    //         label: 'Frame Colour',
    //         required: false,
    //         options: 'frames',
    //     },
    // },
    // replacementCanopy: {
    //     model: {
    //         name: 'model',
    //         label: 'Model *',
    //         required: true,
    //         options: 'models',
    //     },
    //     canopyColour: {
    //         name: 'canopyColour',
    //         label: 'Canopy Colours *',
    //         required: true,
    //         options: 'colours',
    //     },
    //     canopyColourTwo: {
    //         name: 'canopyColourTwo',
    //         label: 'Second Canopy Colour',
    //         required: false,
    //         options: 'colours',
    //     },
    //     valance: {
    //         name: 'valance',
    //         label: 'Valance',
    //         required: false,
    //         options: 'colours',
    //     },
    // },
};

export const ProductTypeOptions = [
    {
        code: 'revolvashade',
        displayName: 'Revolvashade',
    },
    {
        code: 'clevershade',
        displayName: 'Clevershade',
    },
    {
        code: 'blacksheep',
        displayName: 'Blacksheep',
    },
    // {
    //     code: 'bags',
    //     displayName: 'Bags',
    // },
    // {
    //     code: 'replacementCanopy',
    //     displayName: 'Replacement Canopies',
    // },
    // {
    //     code: 'bases',
    //     displayName: 'Bases',
    // },
    // {
    //     code: 'repair',
    //     displayName: 'Repair',
    // },
];

export const getProductTypeData = (sourceData = null) => {
    const productData = {};

    mapObjectData(sourceData, productData, PRODUCT_FIELDS);
    return productData;
};

export const getProductName = (productType, modelCode, modelLookup) => {
    // ignore if product type is not found in lookup (temp fix)
    // const parsedProductType = productType === 'replacement canopies' ? 'replacementCanopy' : productType;

    // if (!modelLookup || !modelLookup[parsedProductType]) return;

    // const model = modelLookup[parsedProductType].find((modelData) => modelData.code === modelCode);

    const model = modelLookup.find((modelData) => modelData.code === modelCode);
    return `${model.displayName}`;
};

export const getProductData = (sourceData) => {
    if (!sourceData) return;

    const data = {
        ...sourceData,
        product: ProductTypeOptions.find((type) => type.displayName === sourceData.model.product).code,
        model: sourceData.model.code,
    };

    // clean the data
    const productData = getProductTypeData(data.product, data);

    productData.product = data.product;
    productData.id = data.id || null;
    productData.base = data.base && typeof data.base === 'object' ? data.base.code : data.base;

    return productData;
};
